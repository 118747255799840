import { mapActions } from 'vuex';
export const productsCore = {
  computed: {
    products() {
      return this.$store.getters.getProducts;
    },
    page: {
      get() {
        if (parseInt(this.$route.query.page) !== this.$store.getters.getPage) {
          this.$router.push({ path: 'products', query: {page: this.$store.getters.getPage }})
        }
        return this.$store.getters.getPage;
      },
      set(page) {
        this.$store.commit("setPage", page);
      }
    },
    pages() {
      return this.$store.getters.getPages;
    },
    skuCount() {
      return this.$store.getters.getOrganizationState.sku_count;
    },
    search() {
      return this.$store.getters.getSearch;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    changes() {
      return JSON.stringify(this.$store.state.products.oldData.products) !== JSON.stringify(this.$store.state.products.newData.products);
    },
  },
  created: function() {
    if (!this.$store.getters.getMixinActive('products')) {
      this.$store.dispatch("getProducts");
    }
    this.$store.commit('setMixinActive',  [ true, 'products'] );
  },
  watch: {
    page: function() {
      this.$store.dispatch("getProducts");
    },
  },
  methods: {
    searchAllProducts() {
      this.$store.dispatch("getProducts");
    },
  }
};

export const productCore = {
  computed: {
    additionalSkus() {
      // Only perform if variants exist
      if (this.$store.state.products.oldData.product.variants !== undefined && this.$store.state.products.newData.product.variants !== undefined) {
        // The additonal skus are all the new ones which have not been deleted (or never were deleted)
        // minus all the old ones which have not been deleted (or never were deleted)
        return this.$store.state.products.newData.product.variants.filter(x => x.is_deleted  === undefined || x.is_deleted  === false).length - this.$store.state.products.oldData.product.variants.filter(x => x.is_deleted  === undefined || x.is_deleted  === false).length
      } else {
        return 0;
      }
    },
    product() {
      return this.$store.getters.getProduct;
    },
    changes() {
      return JSON.stringify(this.$store.state.products.oldData.product) !== JSON.stringify(this.$store.state.products.newData.product);
    },
    next_product() {
      return this.$store.getters.getNextProduct;
    },
    previous_product() {
      return this.$store.getters.getPreviousProduct;
    },
  },
  created: function() {
    this.$store.dispatch("getProduct", this.$route.params.id);
  },
  watch: {
    '$route.params.id': {
      handler: async function(newId, oldId) {
        // Only refetch if ID has changed
        if (oldId && newId !== oldId) {
          await this.$store.dispatch("getProduct", newId);
          await this.getWebsiteData(newId);
        }
      },
      immediate: true
    }
  },
  methods: {
    async getWebsiteData(product_id) {
      for (var i in this.$store.getters.getWebsites) {
        let website = this.$store.getters.getWebsites[i];
        // Get the website options for the product
        await this.$store.dispatch("getProductWebsiteOptions", { product_id: product_id, website: website });
        
        // Get the website options for the website
        await this.$store.dispatch("getWebsiteOptions", { product_id: product_id, website: website });
      }
    }
  }
};

export const productsLogic = {
  methods: {
    goToProduct(product_id) {
      // Stop navigation if changes made
      if (this.changes) {
        this.$refs.unsaved_changes_dialog.showDialog('Unsaved Changes', 'Are you sure you want to continue. Any unsaved changes will be lost.')
          .then(() => {
            this.$router.push(`/products/${product_id}`);
          }).catch(() => {});
      } else {
        this.$router.push(`/products/${product_id}`);
      }
    },
    ...mapActions({
      uploadProduct: 'uploadProduct',
      updateProduct: 'updateProduct',
      deleteProduct: 'deleteProduct',
      duplicateProduct: 'duplicateProduct',
      uploadVariants: 'uploadVariants',
      updateVariants: 'updateVariants',
      deleteVariants: 'deleteVariants',
      uploadImages: 'uploadImages',
      uploadVariantImages: 'uploadVariantImages',
      updateImages: 'updateImages',
      deleteImages: 'deleteImages',
      updateWebsiteProducts: 'updateWebsiteProducts',
      updateWebsiteVariants: 'updateWebsiteVariants',
      uploadWebsiteTags: 'uploadWebsiteTags',
      deleteProductMetafields: 'deleteProductMetafields',
      deleteVariantMetafields: 'deleteVariantMetafields',
      discardChanges: 'discardProductChanges',
      publishProduct: 'publishProduct',
      updateProductLastUpdated: 'updateProductLastUpdated',
      syncProduct: 'syncProduct',
    })
  },
};
