<template>
  <StepperDialog ref="publish_dialog" title="Publishing Product" max_width="65%" :steps="3">
    <template #confirm-action="{ options }" v-if="shopify_selection_warning">
      <ActionDialog ref="confirm_dialog" title="Confirm Publish" color="warning">
        <template v-slot>
          <span class="warning--text" v-html="'Warning! Are you sure you want to publish this product?<br><br>Unselected variants will be permanently removed from selected Shopify sites. Any associated data such as pricing will be lost.'"></span>
        </template>
      </ActionDialog>
      <v-btn class="rounded-lg" outlined color="warning" @click="$refs.publish_dialog.$refs.step_3[0].validate() ? $refs.confirm_dialog.showDialog().then(() => options.confirm(options.data)).catch(()=>{}):''">Confirm</v-btn>
    </template>

    <template #step_1_header><span :class="{ 'warning--text': shopify_selection_warning }">Variants</span></template>
    <template #step_1_subheader v-if="shopify_selection_warning"><span class="mb-n1 warning--text">Unselected Variants</span></template>
    <template #step_1_content="{ options }">
      <div v-for="(product, i) in options.data.products" :key="product.product_id">
        <v-select
          v-if="product.variants"
          v-model="product.selected_variants"
          :items="product.variants"
          :rules="[v => v.length >= 1 || 'You must select at least one variant']"
          :label="`${product.name} Variants To Publish`"
          :item-text="product.variants[0].variant_name ? 'variant_name' : 'sku'"
          :messages="product.selected_variants.length && product.selected_variants.length < product.variants.length ? 'Warning, unselected variants will be removed from Shopify sites.' : ''"
          item-value="variant_id"
          outlined
          multiple
          chips
          :hide-details="i < options.data.products.length-1 ? false : 'auto'"
          class="rounded-lg"
          @change="variants_unselected = product.selected_variants.length < product.variants.length && product.selected_variants.length !== 0"
        >
          <template v-slot:message="{ message }">
            <span :class="{ 'warning--text': product.selected_variants.length && product.selected_variants.length < product.variants.length }">{{ message }}</span>
          </template>
        </v-select>
      </div>
    </template>

    <template #step_2_header>Websites</template>
    <template #step_2_content="{ options }">
      <v-select
        v-if="websites"
        v-model="options.data.websites"
        :items="websites"
        :rules="[v => v.length >= 1 || 'You must select at least one website']"
        label="Websites To Publish To"
        item-text="name"
        return-object
        outlined
        multiple
        chips
        hide-details=auto
        class="rounded-lg"
        @change="shopify_site_selected = options.data.websites.filter(x => x.type === 'Shopify').length !== 0;"
      >
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
            <v-checkbox :input-value="active"></v-checkbox><v-img :src="item.image_url" max-width="50px"/>&nbsp;{{ item.name }}  
          </v-list-item>
        </template>
      </v-select>
    </template>

    <template #step_3_header>Advanced Options</template>
    <template #step_3_content="{ options }">
      <v-select
        v-model="options.data.type"
        :items="['Update Only', 'Create Only', 'Update and Create']"
        :rules="[v => !!v || 'You must select a Publish Type']"
        label="Publish Type"
        outlined
        class="rounded-lg"
      ></v-select>
      <v-select
        v-model="options.data.advanced_options"
        :items="advanced_options"
        label="Advanced Options"
        outlined
        multiple
        chips
        placeholder="All Options"
        persistent-placeholder
        hint="Select only options you wish to publish"
        persistent-hint
        clearable
        hide-details=auto
        class="rounded-lg"
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @mousedown.prevent @click="toggleAdvancedOptions">
            <v-list-item-action>
              <v-icon :color="options.data.advanced_options.length > 0 ? 'indigo darken-4' : ''">
                {{ advanced_icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Select All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </template>
  </StepperDialog>
</template>

<script>
import StepperDialog from "./StepperDialog.vue"
import ActionDialog from "./ActionDialog.vue"

export default {
  components: {
    StepperDialog,
    ActionDialog,
  },
  props: {
    products: Array,
    websites: Array,
  },
  data() {
    return {
      shopify_site_selected: false,
      variants_unselected: false,
      advanced_options: ['Name', 'Brand', 'Description', 'Images', 'SKU', 'Barcode', 'Pricing', 'Categories/Tags', 'Active/Status', 'Localized Name', 'Localized Description', 'Meta Information', 'Site Settings', 'Metafields'],
      publish_options: {
        products: [],
        websites: [],
        type: '',
        advanced_options: []
      }
    }
  },
  computed: {
    shopify_selection_warning() {
      return this.shopify_site_selected && this.variants_unselected;
    },
    all_advanced_options() {
      return this.publish_options.advanced_options.length === this.advanced_options.length;
    },
    some_advanced_options() {
      return this.publish_options.advanced_options.length > 0 && !this.all_advanced_options;
    },
    advanced_icon() {
      if (this.all_advanced_options) return 'mdi-close-box'
      if (this.some_advanced_options) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline';
    }
  },
  methods: {
    show() {
      return this.$refs.publish_dialog.showDialog(this.publish_options)
        .then(data => {
          const multi_publish = data.products.length > 1;
          let promises = [];
          let payloads = [];
          // Publish all products from dialog
          for (var product of data.products) {
            for (var website of data.websites) {
              let publish_payload = {
                product_id: product.product_id,
                product_name: product.name,
                website_id: website.website_id,
                website_name: website.name,
                data: {
                  variants: product.selected_variants,
                  type: data.type,
                  advanced_options: data.advanced_options
                }
              }
              if (multi_publish) {
                payloads.push(publish_payload);
              } else {
                promises.push(this.$store.dispatch("publishProduct", publish_payload));
              }
            }
          }
          if (multi_publish) {
            promises.push(this.$store.dispatch("publishProducts", payloads));
          }
          return Promise.all(promises);
        })
        .catch(() => {
          this.publish_options.websites = [];
          this.publish_options.advanced_options = [];
          this.publish_options.type = '';
        });
    },
    toggleAdvancedOptions() {
      if (this.all_advanced_options) {
        this.publish_options.advanced_options = [];
      } else {
        this.publish_options.advanced_options = this.advanced_options.slice();
      }
    }
  },
  watch: {
    products(products) {
      this.variants_unselected = false;
      this.publish_options.products =  JSON.parse(JSON.stringify(products));
      // When products prop comes in, update the default values
      for (var product of this.publish_options.products) {
        if (Object.keys(product).length > 0) {
          product.selected_variants = product.variants.map(x => x.variant_id);
        }
      }
    }
  }
}
</script>