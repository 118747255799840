<template>
  <ckeditor
    v-model="input_text"
    :read-only="!access.update_access || disabled"
    :config="editorConfig"
    @ready="first_load = false"
    @namespaceloaded="onNamespaceLoaded"
  ></ckeditor>
</template>

<script>
import CKEditor from 'ckeditor4-vue';
export default {
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    value: String,
    access: Object,
    disabled: Boolean,
  },
  data() {
    return {
      first_load: true,
      editorConfig: {
        versionCheck: false,
        extraPlugins: ['colorbutton', 'colordialog', 'youtube'],
        allowedContent: true,
        toolbar: [
          ['Undo', 'Redo'],
          ['Format', 'Font', 'FontSize'],
          ['Bold', 'Italic', 'Underline', 'Strike', 'TextColor', 'BGColor'],
          ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyFull'],
          ['BulletedList', 'NumberedList'],
          ['Link', 'Image', 'Youtube'],
          ['HorizontalRule'],
          ['Source'],
          ['Maximize']
          ],
        removeButtons: null
      },
    }
  },
  computed: {
    input_text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);

        // If a change is made but first load is true,
        // means that the raw HTML had to be parsed.
        // First load is set to false after this event
        if (this.first_load) {
          // Update old product to have parsed description
          this.$store.commit('editProduct');
        }
      }
    }
  },
  methods: {
    onNamespaceLoaded( CKEDITOR ) {
      CKEDITOR.plugins.addExternal( 'youtube', 'https://cdn.jsdelivr.net/npm/ckeditor-youtube-plugin@2.1.19/youtube/plugin.js' );
    }
  }
}
</script>