<template>
  <v-card class="rounded-lg">
    <v-form>
      <v-card-title class="justify-center py-3" v-if="!hide_header">
        {{ title }}
      </v-card-title>

      <v-divider v-if="!hide_header"/>

      <v-card-text>
        <VueFileAgent
          v-model="images"
          :deletable="true"
          sortable="handle"
          :accept="'image/*'"
          :maxSize="'10MB'"
          :helpText="'Choose images or drag & drop here'"
          :errorText="{
            type: 'Invalid file type. Only images Allowed',
            size: 'Files should not exceed 10MB in size',
          }"
          @input="$emit('update:images', $event)"
          @beforedelete="removeImage($event)"
          ref="fileAgent"
        ></VueFileAgent>
      </v-card-text>

      <v-divider v-if="settings !== null || !hide_actions"/>

      <v-card-actions class="justify-center px-3">
        <v-btn text @click="advanced_options = !advanced_options" v-if="!hide_advanced && settings !== null">
          Advanced Options
          <v-icon :style="`${advanced_options ? 'transform: rotate(180deg)' : ''}`">mdi-chevron-down</v-icon>
        </v-btn>
        <v-spacer v-if="settings !== null"/>
        <v-btn class="rounded-lg" color="primary" outlined v-if="!hide_actions" @click="upload({ images, settings }); $emit('update:show', false); $emit('update:droppedImages', []); images = [];" :disabled="!upload_valid || !images_valid">Upload</v-btn>
        <v-btn class="rounded-lg" color="grey darken-1" text v-if="!hide_actions" @click="$emit('update:show', false); $emit('update:droppedImages', []); images = [];">Cancel</v-btn>
      </v-card-actions>
      <v-expand-transition v-if="!hide_advanced && settings !== null">
        <div v-show="advanced_options">
          <v-divider class="pb-1"/>
          <v-container class="pa-4">
            <v-form
              v-model="upload_valid"
            >
              <SettingsTabsImagesOptions
                :settings="settings"
                use_settings
                :key="advanced_options"
              ></SettingsTabsImagesOptions>
            </v-form>
          </v-container>
        </div>
      </v-expand-transition>
    </v-form>
  </v-card>
</template>

<script>
import SettingsTabsImagesOptions from "@/components/settings/SettingsTabsImagesOptions.vue";
export default {
  props: {
    title: {
      type: String,
      default: "Upload Images"
    },
    hide_header: Boolean,
    hide_actions: Boolean,
    hide_advanced: Boolean,
    droppedImages: Array,
    upload: Function,
  },
  components: {
    SettingsTabsImagesOptions,
  },
  data() {
    return {
      upload_valid: true,
      images: [],
      advanced_options: false,
      settings: this.$store.getters.getImageSettings,
    }
  },
  computed: {
    images_valid() {
      for (var i = 0; i < this.images.length; i++) {
        if (this.images[i].error) {
          return false;
        }
      }
      return true;
    }
  },
  mounted() {
    // If drop images already exsist, add them to images
    if (this.droppedImages !== undefined && this.droppedImages.length > 0) {
      this.droppedImages.forEach(image => {
        if (this.images.find(x => x.file.name == image.file.name) == undefined) {
          this.images.push(image);
        }
      });
    }
  },
  watch: {
    droppedImages(images) {
      images.forEach(image => {
        // If the image is not already in the new images list, push it
        if (this.images.find(x => x.file.name == image.file.name) == undefined) {
          this.images.push(image);
        }
      });
    }
  },
  methods: {
    removeImage(image) {
      this.$refs.fileAgent.deleteFileRecord(image);
    }
  }
}
</script>